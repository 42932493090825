import { getUniversalHelpers } from "../../helpers";

export async function conversation__client__setUnread(p: { conversationId: string; accountId: string; setting: boolean }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const { conversationId, setting, accountId } = p;
  await h.AccountPrivate.update({
    id: accountId,
    doc: {
      settings: {
        conversations: {
          [conversationId]: {
            markedAsUnread: setting
          }
        }
      }
    }
  });
}

// i18n certified - complete
